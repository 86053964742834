import React from "react";

import { Toolbar, IconButton, Typography, makeStyles, Button, MenuItem, Hidden, Grid, Box } from "@material-ui/core";
import { Link } from 'react-router-dom';

import FotoUsuarioTemp from "../../../default-avatar.png";
import { useStateValue } from "../../../contexto/store";
import AvatarMenu from "componentes/AvatarMenu";
import { useLocalization } from "contexto/localization/useLocalization";
import getInitials from "utils/getInitials";
import TokenService from "servicios/TokenService";
import { useHistory } from 'react-router';
import clsx from "clsx";
import NotificacionesPopper from "./NotificacionesPopper";

const useStyles = makeStyles((theme) => ({
    seccionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    seccionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    grow: {
        flexGrow: 1,
    },
    avatarSize: {
        width: 40,
        height: 40,
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
}));

const BarSesion = ({ openMenu = true, onChangeOpenMenu = (state) => { } }) => {
    const { strings: s } = useLocalization();
    const strings = s.menus;

    const history = useHistory();
    const classes = useStyles();
    const [{ sesionUsuario }, dispatch] = useStateValue();

    const salirSesionApp = () => {
        TokenService.removeTokens()

        dispatch({
            type: "SALIR_SESION",
            nuevoUsuario: null,
            autenticado: false
        })

        history.push("/auth/login");
    };

    return (
        <>
            <Toolbar>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Box display="flex" alignItems="center">
                            <Box>
                                <IconButton
                                    color="inherit"
                                    onClick={() => onChangeOpenMenu(!openMenu)}
                                    className={clsx(classes.menuButton, { [classes.hide]: openMenu })}
                                >
                                    <i className="material-icons">menu</i>
                                </IconButton>
                            </Box>
                            <Box>
                                <Typography variant="h6">Austranet<strong>GRC</strong></Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Hidden smDown>
                            <Box display="flex" alignItems="center">
                                <Box m={1}>
                                    <Button color="inherit" component={Link} to="/perfil/ajustes">
                                        {sesionUsuario ? sesionUsuario.usuario.nombreCompleto : ""}
                                    </Button>
                                </Box>
                                <Box m={1}>
                                    <NotificacionesPopper/>
                                </Box>
                                <Box m={1}>
                                    <AvatarMenu
                                        label={sesionUsuario ? getInitials(sesionUsuario.usuario.nombreCompleto).slice(0, 2) : "U"}
                                        imageSrc={sesionUsuario.usuario.imagenPerfil || FotoUsuarioTemp}
                                        menuItems={
                                            [
                                                (<MenuItem key="m_1" onClick={() => history.push("/perfil/ajustes")}>{strings.miPerfil}</MenuItem>),
                                                (<MenuItem key="m_2" onClick={() => history.push("/configuraciones")}>{strings.configuraciones}</MenuItem>),
                                                (<MenuItem key="m_3" onClick={salirSesionApp}>{strings.cerrarSesion}</MenuItem>)
                                            ]
                                        }
                                    />
                                </Box>
                            </Box>
                        </Hidden>

                        <Hidden mdUp>
                            <Box display="flex" alignItems="center">
                                <Box>
                                    <NotificacionesPopper/>
                                </Box>
                                <Box>
                                    <AvatarMenu
                                        label={sesionUsuario ? getInitials(sesionUsuario.usuario.nombreCompleto).slice(0, 2) : "U"}
                                        imageSrc={sesionUsuario.usuario.imagenPerfil || FotoUsuarioTemp}
                                        menuItems={
                                            [
                                                (<MenuItem key="m_1" onClick={() => history.push("/perfil/ajustes")}>{strings.miPerfil}</MenuItem>),
                                                (<MenuItem key="m_2" onClick={() => history.push("/configuraciones")}>{strings.configuraciones}</MenuItem>),
                                                (<MenuItem key="m_3" onClick={salirSesionApp}>{strings.cerrarSesion}</MenuItem>)
                                            ]
                                        }
                                    />
                                </Box>
                            </Box>
                        </Hidden>
                    </Grid>
                </Grid>
            </Toolbar>
        </>
    );
};

export default BarSesion;
